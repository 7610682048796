// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-this-site-index-js": () => import("./../../../src/pages/about-this-site/index.js" /* webpackChunkName: "component---src-pages-about-this-site-index-js" */),
  "component---src-pages-forms-index-js": () => import("./../../../src/pages/forms/index.js" /* webpackChunkName: "component---src-pages-forms-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-index-js": () => import("./../../../src/pages/newsletter/index.js" /* webpackChunkName: "component---src-pages-newsletter-index-js" */),
  "component---src-pages-organization-index-js": () => import("./../../../src/pages/organization/index.js" /* webpackChunkName: "component---src-pages-organization-index-js" */),
  "component---src-pages-rejectomancy-index-js": () => import("./../../../src/pages/rejectomancy/index.js" /* webpackChunkName: "component---src-pages-rejectomancy-index-js" */),
  "component---src-pages-submit-index-js": () => import("./../../../src/pages/submit/index.js" /* webpackChunkName: "component---src-pages-submit-index-js" */),
  "component---src-pages-subscribe-index-js": () => import("./../../../src/pages/subscribe/index.js" /* webpackChunkName: "component---src-pages-subscribe-index-js" */),
  "component---src-templates-archive-author-js": () => import("./../../../src/templates/archiveAuthor.js" /* webpackChunkName: "component---src-templates-archive-author-js" */),
  "component---src-templates-archive-content-js": () => import("./../../../src/templates/archiveContent.js" /* webpackChunkName: "component---src-templates-archive-content-js" */),
  "component---src-templates-archive-issues-js": () => import("./../../../src/templates/archiveIssues.js" /* webpackChunkName: "component---src-templates-archive-issues-js" */),
  "component---src-templates-eachauthor-js": () => import("./../../../src/templates/eachauthor.js" /* webpackChunkName: "component---src-templates-eachauthor-js" */),
  "component---src-templates-eachissue-js": () => import("./../../../src/templates/eachissue.js" /* webpackChunkName: "component---src-templates-eachissue-js" */),
  "component---src-templates-eachpost-js": () => import("./../../../src/templates/eachpost.js" /* webpackChunkName: "component---src-templates-eachpost-js" */)
}

